import Swal from "sweetalert2";
import "../../../assets/app/styles/tables.css";
import DateTime from "./dateTime";

import { MODAL } from "../../../constants/modalTypes";
import { Component, useEffect, useState } from "react";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import {
  getGlobalAuthData,
  GlobalLocalStorages,
  GlobalStates,
  setGlobalAuthData,
} from "config/global";
import Functions from "../../../config/global/functions/index";
import Services from "services/index";
import PageIndex from "../pages";
import Printer from "../../../config/global/printers";
import Providers from "../../providers";
import functions from "../../../config/global/functions/index";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  Box,
  IconButton,
  Chip,
  Stack,
  Grid,
  FormControlLabel,
  Switch,
  Paper,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Screen from "../../../config/global/printers/screen/screen";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { toast } from "react-toastify";
import { buttons } from "constants/checkButtons";
import Currencies from "constants/currencies";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { green, orange, red } from "@mui/material/colors";
import GetirService from "services/getir-service";
import { styled } from "@mui/system";
import checkPerm from "config/global/functions/perm/checkPerm";
const ipcRenderer: any = window.require("electron").ipcRenderer;

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: 16,
    padding: "24px",
    backgroundColor: "#f5f5f5",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: "1.8rem",
  fontWeight: "bold",
  textAlign: "center",
  color: "#333",
  marginBottom: "16px",
});

const StyledStack = styled(Stack)({
  marginLeft: "10vw",
  marginRight: "10vw",
  paddingTop: "1vw",
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 32,
  paddingBottom: 32,
  backgroundColor: "#fff",
  borderRadius: 12,
  boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.05)",
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: "center",
  paddingBottom: 24,
});

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 16,
  borderRadius: 12,
  backgroundColor: "#fafafa",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
});

const StyledTypography = styled(Typography)({
  fontSize: "1.2rem",
  marginBottom: 12,
  color: "#555",
});

type PageState = {
  settingsOpen: boolean;
  stocksOpen: boolean;
  isFullScreen: boolean;
  isMainComputer: boolean;
  isHomeDeliveryOpen: boolean;
  buttonSettingsOpen: boolean;
  interfaceSettingsOpen: boolean;
  currencySettingsOpen: boolean;
  caseSaleProcess: boolean;
  integrationDialogOpen: boolean;
  getirDialogOpen: boolean;
};

type PageProps = {
  pageIndex: PageIndex;
} & PagePropCommonDocument;

class Operations extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      settingsOpen: false,
      stocksOpen: false,
      isFullScreen: document.fullscreenEnabled,
      isMainComputer:
        localStorage.getItem("isMainComputer") === "true" ? true : false,
      isHomeDeliveryOpen:
        localStorage.getItem("isHomeDeliveryOpen") === "true" ? true : false,
      buttonSettingsOpen: false,
      interfaceSettingsOpen: false,
      currencySettingsOpen: false,
      caseSaleProcess: false,
      integrationDialogOpen: false,
      getirDialogOpen: false,
    };
  }

  caseStatePopup(t: any): void {
    if (!functions.checkPerm("511")) {
      Swal.fire({
        icon: "error",
        title: this.props.router.t("auth-err"),
      });
      return;
    }
    let getMyCase = Services.Get.case({}).data;
    let getCost = Services.Get.costs().data;
    getMyCase &&
      Swal.fire({
        title: `<b>${t("case-status")}</b>`,
        width: "800px",
        html: `
                <div style="display: flex; flex-direction: row; justify-content: space-evenly">
                    <div>
                        <b>${t("tickets-paid")}:</b> ${getMyCase?.checks.length}
                        <br/>
                        <b>${t("opening-amount")}:</b> ${
          getMyCase?.start_balance[0]?.amount
        } ${getMyCase?.start_balance[0]?.currency}
                        <br/>
                        <b>${t("total-revenue")}:</b> ${this.getTotalAmount(
          getMyCase
        ).toFixed(2)} ${getMyCase?.start_balance[0]?.currency}
                        <br/>
                        <b>${t("total-tips")}</b> ${this.getTotalTip(
          getMyCase
        ).toFixed(2)} ${getMyCase?.start_balance[0]?.currency}
                        <br/>
                        <b>${t("expenses")}: </b> ${
          getMyCase?.expense_amount
        } ${getMyCase?.start_balance[0]?.currency}
                        <br />
                        <b>${t("cancelled-quantity")}:</b> ${
          getMyCase?.cancelled?.cancelQuantity
        }
                        <br/>
                        <b>${t("cancelled-amount")}:</b> ${
          getMyCase?.cancelled?.cancelAmount
        } ${getMyCase?.start_balance[0]?.currency}
                        <br/>
                        <b>${t("catering-quantity")}:</b> ${
          getMyCase?.catering?.cateringQuantity
        }
                        <br/>
                        <b>${t("catering-amount")}:</b> ${
          getMyCase?.catering?.cateringAmount
        } ${getMyCase?.start_balance[0]?.currency}
                        <br/>

                    </div>
                    <div>
                        <h4><b>${t("open-checks")}:</b> ${
          getMyCase?.openChecks?.quantity
        }</h4>
                        <hr />
                        <b>${t("total-amount")}:</b> ${
          getMyCase?.openChecks?.totalAmount
        } ${getMyCase?.start_balance[0]?.currency}
                        <br/>
                        <b>${t("cancelled-quantity")}:</b> ${
          getMyCase?.openChecks?.cancelled?.cancelQuantity
        }
                        <br/>
                        <b>${t("cancelled-amount")}:</b> ${
          getMyCase?.openChecks?.cancelled?.cancelAmount
        } ${getMyCase?.start_balance[0]?.currency}
                        <br/>
                        <b>${t("catering-quantity")}:</b> ${
          getMyCase?.openChecks?.catering?.cateringQuantity
        }
                        <br/>
                        <b>${t("catering-amount")}:</b> ${
          getMyCase?.openChecks?.catering?.cateringAmount
        } ${getMyCase?.start_balance[0]?.currency}
                    </div>
                </div>
                
                `,
      });
  }

  getTotalAmount(myCase: any) {
    let sum_payment = 0;
    let start_balance = myCase?.start_balance[0]?.amount;
    myCase.balance
      .filter((payment: any) => payment.type != 14 && payment.type != 16)
      .map((payment: any) => (sum_payment += payment.amount));
    return sum_payment + start_balance;
  }

  getTotalTip(myCase: any) {
    let tipAmount = 0;
    myCase.balance
      .filter((payment: any) => payment.type == 14)
      .map((payment: any) => (tipAmount += payment.amount));
    return tipAmount;
  }

  getTotalPayment(myCase: any, cost: any) {
    let sum = 0;
    let sum_payment = 0;
    let start_balance = myCase.start_balance[0]?.amount;
    let sum_cost = 0;
    let today = new Date().toLocaleDateString();
    myCase.balance.forEach(
      (payment: any) => (sum_payment += Number(payment.amount))
    );
    cost.forEach((cost: any) => {
      let costDate = new Date(cost?.createdAt).toLocaleDateString();
      if (today == costDate) {
        sum_cost += cost.expense_amount;
      }
    });
    sum = Number(start_balance) + Number(sum_payment) - Number(sum_cost);
    return sum;
  }

  getTotalCost(cost: any) {
    let sum = 0;
    let today = new Date().toLocaleDateString();
    cost.forEach((cost: any) => {
      let costDate = new Date(cost?.createdAt).toLocaleDateString();
      if (today == costDate) {
        sum += cost.expense_amount;
      }
    });
    return sum;
  }

  createBrowserWindow(url: string) {
    window.require("electron").ipcRenderer.send("newscreen", url);
  }

  handleNewTab = (perm: string) => {
    if (Functions.checkPerm(perm)) {
      switch (perm) {
        case "517":
          this.createBrowserWindow("https://manager.digigarson.com/sign-in");
          break;
        case "516":
          this.createBrowserWindow("https://analysis.digigarson.com/sign-in");

          break;
        default:
          break;
      }
    } else {
      Swal.fire({
        icon: "error",
        title: this.props.router.t("auth-err"),
      });
    }
  };

  signOut() {
    GlobalLocalStorages.Token.delete();
    GlobalLocalStorages.User.delete();
    GlobalStates.firstNavigate = true;
    this.props.router.navigate("sign-in", { replace: true });
  }

  safeSales() {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "isSafeSales",
      true
    );
    if (table) {
      this.props.pageIndex.handleTableClick(table);
    }
  }

  caseClose() {
    if (!functions.checkPerm("511")) {
      Swal.fire({
        icon: "error",
        title: this.props.router.t("auth-err"),
      });
      return;
    }

    const message = Functions.checkForOpenTable()
      ? this.props.router.t("there-are-open-tables") +
        "." +
        this.props.router.t("close-case-description")
      : this.props.router.t("close-case-description");
    Swal.fire({
      title: `${message}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.props.router.t("yes"),
      cancelButtonText: this.props.router.t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        let report: any = Services.Get.zReport().data;

        if (!report) return;

        if (report.payments.length > 0) {
          Printer.ZReport.printZReport(report, this.props.router.t);
          Services.Put.case({
            isClose: true,
            caseId: this.props.getGlobalData.caseId,
          }).then((resData) => {
            if (resData.status) {
              Swal.fire({
                title: this.props.router.t("case-closed"),
                icon: "success",
              });
              window.location.href = "/";
            }
          });
        } else {
          Swal.fire({
            title: this.props.router.t("report-warning"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.props.router.t("yes"),
            cancelButtonText: this.props.router.t("cancel"),
          }).then((result: any) => {
            if (result.isConfirmed) {
              Services.Put.case({
                isClose: true,
                caseId: this.props.getGlobalData.caseId,
              }).then((resData) => {
                if (resData.status) {
                  Swal.fire({
                    title: this.props.router.t("case-closed"),
                    icon: "success",
                  });
                  window.location.href = "/";
                }
              });
            }
          });
        }
      }
    });
  }

  ButtonSettingsDialog = () => {
    const [selectedButtons, setSelectedButtons] = useState("rightButtons");
    const [rightButtons, setRightButtons]: any = useState(
      JSON.parse(localStorage.getItem("rightButtons")!) || []
    );
    const [bottomButtons, setBottomButtons] = useState(
      JSON.parse(localStorage.getItem("bottomButtons")!) || []
    );
    const [isChanged, setIsChanged] = useState(false);

    const isSelected = (name: string) => {
      switch (selectedButtons) {
        case "rightButtons":
          return rightButtons?.find((b: any) => b.name == name);
        case "bottomButtons":
          return bottomButtons?.find((b: any) => b.name == name);
        default:
          return false;
      }
    };

    const addButtons = async (button: any) => {
      setIsChanged(true);
      switch (selectedButtons) {
        case "rightButtons":
          let i = rightButtons?.findIndex(
            (btn: any) => btn.name == button.name
          );
          if (i > -1) {
            setRightButtons(
              rightButtons?.filter((btn: any) => btn.name != button.name)
            );
            localStorage.setItem(
              "rightButtons",
              JSON.stringify(
                rightButtons?.filter((btn: any) => btn.name != button.name)
              )
            );
            return;
          }
          setRightButtons([...rightButtons, button]);
          localStorage.setItem(
            "rightButtons",
            JSON.stringify([...rightButtons, button])
          );
          break;
        case "bottomButtons":
          let index = bottomButtons?.findIndex(
            (btn: any) => btn.name == button.name
          );
          if (index > -1) {
            setBottomButtons(
              bottomButtons?.filter((btn: any) => btn.name != button.name)
            );
            localStorage.setItem(
              "bottomButtons",
              JSON.stringify(
                bottomButtons?.filter((btn: any) => btn.name != button.name)
              )
            );
            return;
          }
          setBottomButtons([...bottomButtons, button]);
          localStorage.setItem(
            "bottomButtons",
            JSON.stringify([...bottomButtons, button])
          );
          break;
        default:
          break;
      }
    };

    const reset = () => {
      setIsChanged(true);
      setBottomButtons([]);
      setRightButtons([]);
      localStorage.removeItem("rightButtons");
      localStorage.removeItem("bottomButtons");
    };
    return (
      <Dialog
        open={this.state.buttonSettingsOpen}
        onClose={() => {
          if (isChanged) {
            window.location.reload();
            return;
          }
          this.setState({ buttonSettingsOpen: false });
        }}
        maxWidth="lg"
      >
        <DialogTitle>{this.props.router.t("button-settings")}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3} alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <Grid container justifyContent="left">
                <Select
                  value={selectedButtons}
                  onChange={(e: any) => setSelectedButtons(e.target.value)}
                >
                  {["rightButtons", "bottomButtons"].map((btns: any) => (
                    <MenuItem value={btns}>
                      {this.props.router.t(btns)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid container justifyContent="right">
                <Button
                  variant="contained"
                  onClick={() => reset()}
                  color="inherit"
                >
                  {this.props.router.t("reset")}
                </Button>
              </Grid>
            </Stack>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {buttons.map((btn: any) => (
                <Grid item xs={3}>
                  <Typography
                    onClick={() => addButtons(btn)}
                    sx={{
                      backgroundColor: isSelected(btn.name)
                        ? "green"
                        : "#D42016",
                      padding: "0.5vw",
                      textAlign: "center",
                      borderRadius: 4,
                      color: "#fff",
                    }}
                  >
                    {this.props.router.t(btn.name)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setIsChanged(false);
              window.location.reload();
            }}
          >
            {this.props.router.t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  handleScreenInput(e: any) {
    Printer.Settings.CurrentScreen = e.target.value;
    Printer.Settings.saveSettings(0);
  }
  screenLength() {
    return Screen.Screens.length;
  }
  ScreenList({ selected }: { selected: string }) {
    const Display = Screen.Screens;

    return Display.map((x: any) => (
      <option selected={x.id.toString() === selected?.toString()} value={x.id}>
        {x.size.width + " x " + x.size.height}
      </option>
    ));
  }
  InterfaceSettingsDialog = () => {
    const [textColor, setTextColor] = useState(
      localStorage.getItem("textColor") || "#ffffff"
    );
    const [showImages, setShowImages] = useState(
      JSON.parse(localStorage.getItem("showImages")!) || false
    );
    const [activeOrder, setActiveOrder] = useState(
      JSON.parse(localStorage.getItem("activeOrder")!) || false
    );

    const [tableTimer, setTableTimer] = useState(
      JSON.parse(localStorage.getItem("tableTimer")!) == null
        ? true
        : JSON.parse(localStorage.getItem("tableTimer")!)
    );
    const [showScreen, setShowScreen] = useState(
      JSON.parse(localStorage.getItem("new-screen")!)?.id || ""
    );
    const [isScreen, setIsScreen] = useState(
      JSON.parse(localStorage.getItem("new-screen")!)?.bool || false
    );
    const [isChanged, setIsChanged] = useState(false);

    const onClose = () => {
      this.setState({ interfaceSettingsOpen: false });
      if (isChanged) {
        window.location.reload();
      }
    };

    const settingsOnChange = (type: string, value?: any) => {
      setIsChanged(true);
      switch (type) {
        case "showImages":
          setShowImages(!showImages);
          localStorage.setItem("showImages", JSON.stringify(!showImages));
          break;
        case "textColor":
          setTextColor(value);
          localStorage.setItem("textColor", value);
          break;
        case "activeOrder":
          setActiveOrder(!activeOrder);
          localStorage.setItem("activeOrder", JSON.stringify(!activeOrder));
          break;
        case "tableTimer":
          setTableTimer(!tableTimer);
          localStorage.setItem("tableTimer", JSON.stringify(!tableTimer));
          break;
        case "screenShow":
          setShowScreen(JSON.stringify({ bool: isScreen, id: value }));
          localStorage.setItem(
            "new-screen",
            JSON.stringify({ bool: isScreen, id: value })
          );
          break;
        case "showBool":
          setIsScreen(!isScreen);
          localStorage.setItem(
            "new-screen",
            JSON.stringify({ bool: !isScreen, id: showScreen })
          );
          break;
        default:
          break;
      }
    };
    return (
      <Dialog open={this.state.interfaceSettingsOpen} onClose={onClose}>
        <DialogTitle>{this.props.router.t("interface-settings")}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} direction="column">
            <Box>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={showImages}
                    onChange={() => settingsOnChange("showImages")}
                  />
                }
                label={this.props.router.t("show-product-category-images")}
              />
            </Box>
            <Box>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={activeOrder}
                    onChange={() => settingsOnChange("activeOrder")}
                  />
                }
                label={this.props.router.t("active-order")}
              />
            </Box>
            <Box>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={tableTimer}
                    onChange={() => settingsOnChange("tableTimer")}
                  />
                }
                label={this.props.router.t("table-timer")}
              />
            </Box>
            <Box>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <input
                    type="color"
                    style={{
                      width: "2.5vw",
                      height: "2.5vw",
                      marginLeft: "1vw",
                    }}
                    defaultValue={textColor}
                    color={textColor}
                    onChange={(e: any) =>
                      settingsOnChange("textColor", e.target.value)
                    }
                  />
                }
                label={this.props.router.t("product-category-text-color")}
              />
            </Box>
            {this.screenLength() > 1 ? (
              <>
                <Box>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={isScreen}
                        onChange={() => settingsOnChange("showBool")}
                      />
                    }
                    label={this.props.router.t("second-window")}
                  />
                </Box>
                <select
                  onChange={(e) =>
                    settingsOnChange("screenShow", e.target.value)
                  }
                  id="globalPrinter"
                >
                  <this.ScreenList
                    selected={
                      Printer.Settings.CurrentScreen != null
                        ? Printer.Settings.CurrentScreen!
                        : ""
                    }
                  />
                </select>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={onClose}>
            {this.props.router.t("save")}
          </Button>
          <Button variant="outlined" color="error" onClick={onClose}>
            {this.props.router.t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  CurrencySettingsDialog = () => {
    const [currencySettings, setCurrencySettings] = useState(
      this.props.getGlobalData.CurrencySettings
    );
    const defaultCurrency =
      this.props.getGlobalData.CurrencySettings?.defaultCurrency;
    const currencyTemp = this.props.getGlobalData.CurrencySettings;
    const handleChange = (currency: string, value: number) => {
      let temp = { ...currencySettings };
      temp.currencies[currency] = Number(value);
      setCurrencySettings(temp);
    };

    const handleCancel = async () => {
      await setCurrencySettings(this.props.getGlobalData.CurrencySettings);
      this.setState({
        currencySettingsOpen: false,
      });
    };

    const handleSave = async () => {
      if (
        !(currencySettings?.currencies?.TL > 0) ||
        !(currencySettings?.currencies?.USD > 0) ||
        !(currencySettings?.currencies?.EUR > 0) ||
        !(currencySettings?.currencies?.GBP > 0)
      ) {
        toast.warning(this.props.router.t("provide-all-currencies"));
        return;
      }
      let response = await Services.Post.currencies(currencySettings);
      if (response.status) {
        toast.success(this.props.router.t("currencies-updated-successfully"));
        this.props.setGlobalData({
          CurrencySettings: currencySettings,
        });
        this.setState({
          currencySettingsOpen: false,
        });
        return;
      }
      toast.error(this.props.router.t("error"));
    };
    return (
      <Dialog
        open={this.state.currencySettingsOpen}
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{this.props.router.t("currency-settings")}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} alignItems="center">
            <Typography sx={{ fontWeight: "bold" }}>
              {this.props.router.t("default-currency")}: {defaultCurrency}
            </Typography>
            {defaultCurrency == "TL" ? null : (
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    onClick={() => ipcRenderer.send("showKeyboard")}
                    onChange={(e: any) => handleChange("TL", e.target.value)}
                    type="number"
                    label={this.props.router.t("turkish-lira")}
                    defaultValue={currencySettings?.currencies?.TL}
                  />
                  <span style={{ fontSize: "1.2vw" }}>
                    {Currencies[defaultCurrency]}
                  </span>
                </Stack>
              </Box>
            )}
            {defaultCurrency == "USD" ? null : (
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    onClick={() => ipcRenderer.send("showKeyboard")}
                    onChange={(e: any) => handleChange("USD", e.target.value)}
                    type="number"
                    label={this.props.router.t("dollar")}
                    defaultValue={currencySettings?.currencies?.USD}
                  />
                  <span style={{ fontSize: "1.2vw" }}>
                    {Currencies[defaultCurrency]}
                  </span>
                </Stack>
              </Box>
            )}
            {defaultCurrency == "EUR" ? null : (
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    onClick={() => ipcRenderer.send("showKeyboard")}
                    onChange={(e: any) => handleChange("EUR", e.target.value)}
                    type="number"
                    label={this.props.router.t("euro")}
                    defaultValue={currencySettings?.currencies?.EUR}
                  />
                  <span style={{ fontSize: "1.2vw" }}>
                    {Currencies[defaultCurrency]}
                  </span>
                </Stack>
              </Box>
            )}
            {defaultCurrency == "GBP" ? null : (
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    onClick={() => ipcRenderer.send("showKeyboard")}
                    onChange={(e: any) => handleChange("GBP", e.target.value)}
                    type="number"
                    label={this.props.router.t("pound")}
                    defaultValue={currencySettings?.currencies?.GBP}
                  />
                  <span style={{ fontSize: "1.2vw" }}>
                    {Currencies[defaultCurrency]}
                  </span>
                </Stack>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSave} color="success">
            {this.props.router.t("save")}
          </Button>
          <Button variant="outlined" onClick={handleCancel} color="error">
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  StocksDialog = () => {
    const [stocks, setStocks] = useState(this.props.getGlobalData?.stocks);
    // useEffect(() => {
    //   if (!this.state.stocksOpen) return;
    //   if (this.props.getGlobalData?.stocks?.toString() == stocks?.toString())
    //     return;
    //   setStocks(this.props.getGlobalData?.stocks);
    // });
    return (
      <Dialog
        open={this.state.stocksOpen}
        onClose={() => this.setState({ stocksOpen: false })}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              margin: "auto",
              width: "40%",
              padding: "1.5vw",
              backgroundColor: "orange",
              borderRadius: "6vw",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              fontSize="1.9vw"
              fontWeight="bold"
              color="white"
              letterSpacing={2}
            >
              {this.props.router.t("stock-info")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ overflowY: "scroll" }}>
            <Box
              sx={{
                width: "50%",
                margin: "auto",
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "1.5vw",
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  padding: "0.7vw",
                  border: "1px solid gray",
                  backgroundColor: green[500],
                  height: "3vw",
                }}
              >
                <Typography fontSize="1vw" color="black">
                  {this.props.router.t("above-optimum")}
                </Typography>
              </Paper>
              <Paper
                elevation={1}
                sx={{
                  padding: "0.7vw",
                  border: "1px solid gray",
                  backgroundColor: orange[500],
                  height: "3vw",
                }}
              >
                <Typography fontSize="1vw" color="black">
                  {this.props.router.t("below-optimum")}
                </Typography>
              </Paper>
              <Paper
                elevation={1}
                sx={{
                  padding: "0.7vw",
                  border: "1px solid gray",
                  backgroundColor: red[500],
                  height: "3vw",
                }}
              >
                <Typography fontSize="1vw" color="black">
                  {this.props.router.t("below-critical")}
                </Typography>
              </Paper>
            </Box>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              {this.props.getGlobalData?.stocks?.map((stock: any) => (
                <Grid item xs={2} sm={4} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      padding: "0.7vw",
                      border: "1px solid gray",
                      backgroundColor:
                        stock.stock < stock.critical_stock_quantity
                          ? red[500]
                          : stock.stock < stock.optimal_stock_quantity
                          ? orange[500]
                          : green[500],
                    }}
                  >
                    <Typography fontSize="1rem" color="black">
                      {stock.title}: {stock.stock}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ stocksOpen: false })}
          >
            {this.props.router.t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  SettingsDialog = () => {
    return (
      <Dialog
        open={this.state.settingsOpen}
        onClose={() => this.setState({ settingsOpen: false })}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{this.props.router.t("settings")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Box>
                <InputLabel id="languageLabel">
                  {this.props.router.t("language")}
                </InputLabel>
                <Select
                  labelId="languageLabel"
                  value={localStorage.getItem("language")}
                  onChange={(e: any) => {
                    this.props.router.i18n.changeLanguage(e.target.value);
                    window.localStorage.setItem("language", e.target.value);
                    window.location.reload();
                  }}
                >
                  <MenuItem value="tr">
                    {this.props.router.t("turkish")}
                  </MenuItem>
                  <MenuItem value="en">
                    {this.props.router.t("english")}
                  </MenuItem>
                  <MenuItem value="de">
                    {this.props.router.t("german")}
                  </MenuItem>
                  <MenuItem value="ar">
                    {this.props.router.t("arabic")}
                  </MenuItem>
                </Select>
              </Box>
              <Box sx={{ margin: "auto", marginTop: 2 }}>
                <Stack direction="column" spacing={3}>
                  <Chip
                    color={this.state.isMainComputer ? "success" : "primary"}
                    label={this.props.router.t("set-as-main-computer")}
                    onClick={() => {
                      if (this.state.isMainComputer) {
                        localStorage.setItem("isMainComputer", "false");
                        this.setState({
                          isMainComputer: false,
                        });
                        return;
                      }
                      localStorage.setItem("isMainComputer", "true");
                      this.setState({
                        isMainComputer: true,
                      });
                      toast.info(
                        this.props.router.t("setting-main-computer-info"),
                        {
                          autoClose: 15000,
                        }
                      );
                      return;
                    }}
                  />
                  <Chip
                    color={
                      this.state.isHomeDeliveryOpen ? "success" : "primary"
                    }
                    label={this.props.router.t("home-delivery-open")}
                    onClick={() => {
                      if (this.state.isHomeDeliveryOpen) {
                        localStorage.setItem("isHomeDeliveryOpen", "false");
                        this.setState({
                          isHomeDeliveryOpen: false,
                        });
                        window.location.reload();
                        return;
                      }
                      localStorage.setItem("isHomeDeliveryOpen", "true");
                      this.setState({
                        isHomeDeliveryOpen: true,
                      });
                      return;
                    }}
                  />
                </Stack>
              </Box>
            </Box>
            <Box>
              <Stack direction="column" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ interfaceSettingsOpen: true })}
                >
                  {this.props.router.t("interface-settings")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ buttonSettingsOpen: true })}
                >
                  {this.props.router.t("button-settings")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.props.openModal(MODAL.PRINTER_SETTING);
                    this.setState({
                      settingsOpen: false,
                    });
                  }}
                >
                  {this.props.router.t("printer-setting")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (!Functions.checkPerm("523")) {
                      toast.error(this.props.router.t("auth-err"));
                      return;
                    }
                    this.props.router.navigate("/barcode", { replace: true });
                  }}
                >
                  {this.props.router.t("barcode-reader-settings")}
                </Button>
                {/* <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({
                      integrationDialogOpen: true,
                    });
                  }}
                >
                  {this.props.router.t("integration-settings")}
                </Button> */}
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                variant="contained"
                startIcon={
                  this.state.isFullScreen ? (
                    <CloseFullscreenIcon />
                  ) : (
                    <OpenInFullIcon />
                  )
                }
                onClick={
                  this.state.isFullScreen
                    ? () => {
                        window
                          .require("electron")
                          .ipcRenderer.send("exitFullscreen");
                        this.setState({
                          isFullScreen: false,
                        });
                      }
                    : () => {
                        window
                          .require("electron")
                          .ipcRenderer.send("fullscreen");
                        this.setState({
                          isFullScreen: true,
                        });
                      }
                }
              >
                {this.state.isFullScreen
                  ? this.props.router.t("full-screen-off")
                  : this.props.router.t("full-screen")}
              </Button>
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={() => window.location.reload()}
                sx={{ marginBottom: "0.3vw", marginTop: "0.3vw" }}
              >
                {this.props.router.t("refresh")}
              </Button>
              <Button
                variant="contained"
                startIcon={<RestartAltIcon />}
                onClick={() => {
                  localStorage.removeItem("tables");
                  localStorage.removeItem("sections");
                  window.location.reload();
                }}
                sx={{ marginBottom: "0.3vw", marginTop: "0.3vw" }}
              >
                {this.props.router.t("reset-order")}
              </Button>
              <Button
                variant="contained"
                startIcon={<ExitToAppIcon />}
                onClick={() =>
                  window.require("electron").ipcRenderer.send("exit")
                }
              >
                {this.props.router.t("desktop")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ settingsOpen: false })}
          >
            {this.props.router.t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  IntegrationDialog = () => {
    const handleClose = () => {
      this.setState({
        integrationDialogOpen: false,
        settingsOpen: true,
      });
    };

    const handleGetirClick = () => {
      if (!checkPerm("526")) {
        toast.error(this.props.router.t("auth-err"));
        return;
      }
      this.setState({
        getirDialogOpen: true,
      });
    };
    return (
      <StyledDialog
        open={this.state.integrationDialogOpen}
        onClose={handleClose}
        fullWidth
      >
        <StyledDialogTitle>
          {this.props.router.t("integrations")}
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledStack direction="column" spacing={3} alignContent="center">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#9600EC",
                "&:hover": {
                  backgroundColor: "#7600BC",
                },
                padding: "10px 20px",
                borderRadius: "8px",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
              onClick={handleGetirClick}
            >
              {this.props.router.t("getir")}
            </Button>
          </StyledStack>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            {this.props.router.t("close")}
          </Button>
        </StyledDialogActions>
      </StyledDialog>
    );
  };

  GetirDialog = () => {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
      this.setState({
        getirDialogOpen: false,
      });
    };

    const handleRestaurantOpen = async () => {
      setLoading(true);
      let openResponse = await GetirService.openRestaurant();
      if (openResponse) {
        toast.success(this.props.router.t("restaurant-opened"));
        setLoading(false);
        return;
      }
      toast.error(this.props.router.t("error"));
      setLoading(false);
    };

    const handleRestaurantClose = async () => {
      let closeResponse = await GetirService.closeRestaurant();
      if (closeResponse) {
        toast.success(this.props.router.t("restaurant-closed"));
        setLoading(false);
        return;
      }
      toast.error(this.props.router.t("error"));
      setLoading(false);
      return;
    };

    return (
      <StyledDialog open={this.state.getirDialogOpen} onClose={handleClose}>
        <StyledDialogTitle>{this.props.router.t("getir")}</StyledDialogTitle>
        <StyledDialogContent>
          <StyledStack direction="row" spacing={2} alignItems="center">
            <StyledBox className="restaurantStatus">
              <StyledTypography>
                {this.props.router.t("restaurant-status")}
              </StyledTypography>
              <Button
                variant="contained"
                color="success"
                onClick={handleRestaurantOpen}
                sx={{
                  height: "3vw",
                  width: "12vw",
                  fontSize: "1vw",
                  marginBottom: "0.4vw",
                }}
                disabled={loading}
              >
                {this.props.router.t("open-restaurant")}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleRestaurantClose}
                sx={{ height: "3vw", width: "12vw", fontSize: "1vw" }}
                disabled={loading}
              >
                {this.props.router.t("close-restaurant")}
              </Button>
            </StyledBox>
          </StyledStack>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleClose}
            disabled={loading}
          >
            {this.props.router.t("close")}
          </Button>
        </StyledDialogActions>
      </StyledDialog>
    );
  };

  caseSaleOpen = async () => {
    if (this.state.caseSaleProcess) return;
    let tables = this.props.getGlobalData?.AllTables;
    let caseSaleTable = tables.find((t: any) => t.isSafeSales);
    if (!caseSaleTable) {
      this.setState({
        caseSaleProcess: true,
      });
      const pending = toast.loading(this.props.router.t("case-sale-preparing"));
      setTimeout(async () => {
        let response = await Services.Post.createCaseSaleTable();
        if (response.status) {
          toast.update(pending, {
            render: this.props.router.t("case-sale-prepared"),
            type: "success",
            isLoading: false,
          });
          this.props.router.navigate("/case-sale", { replace: true });
          this.setState({
            caseSaleProcess: false,
          });
        } else {
          toast.update(pending, {
            render: this.props.router.t("error"),
            type: "error",
            isLoading: false,
          });
          this.setState({
            caseSaleProcess: false,
          });
        }
      }, 1500);
    } else {
      this.props.router.navigate("/case-sale", { replace: true });
    }
  };

  render() {
    return (
      <section className="Operations">
        <div className="headerBarOperations">
          <div className="in">
            <IconButton
              sx={{ position: "absolute", right: "3vw" }}
              onClick={() => window.location.reload()}
            >
              {" "}
              <RefreshIcon />
            </IconButton>
            <IconButton
              sx={{ position: "absolute", right: "1vw" }}
              onClick={
                this.state.isFullScreen
                  ? () => {
                      window
                        .require("electron")
                        .ipcRenderer.send("exitFullscreen");
                      this.setState({
                        isFullScreen: false,
                      });
                    }
                  : () => {
                      window.require("electron").ipcRenderer.send("fullscreen");
                      this.setState({
                        isFullScreen: true,
                      });
                    }
              }
            >
              {" "}
              {this.state.isFullScreen ? (
                <CloseFullscreenIcon />
              ) : (
                <OpenInFullIcon />
              )}
            </IconButton>
            <div className="profile">
              <div>
                <span>{getGlobalAuthData().user.name}</span> <br />
                <span>{getGlobalAuthData().user.lastname}</span>
              </div>
            </div>
            <div className="date">
              <DateTime {...this.props} />
            </div>
          </div>
        </div>
        <div className="Buttons">
          <article
            onClick={() => {
              if (!functions.checkPerm("518")) {
                Swal.fire({
                  icon: "error",
                  title: this.props.router.t("auth-err"),
                });
                return;
              }
              this.props.openModal(MODAL.CALL_CHECK);
            }}
          >
            {this.props.router.t("call-check")}
          </article>
          <article
            onClick={() =>
              this.props.router.navigate("/takeaway", { replace: true })
            }
          >
            {this.props.router.t("take-away")}
          </article>
          <article onClick={() => this.caseStatePopup(this.props.router.t)}>
            {" "}
            {this.props.router.t("case-status")}{" "}
          </article>
          <article onClick={() => this.safeSales()}>
            {this.props.router.t("case-sale")}
          </article>
          <article
            style={{ background: "blue" }}
            onClick={() => {
              if (!functions.checkPerm("512")) {
                Swal.fire({
                  icon: "error",
                  title: this.props.router.t("auth-err"),
                });
                return;
              }
              this.props.openModal(MODAL.X_REPORT_DETAIL);
            }}
          >
            {this.props.router.t("x-report")}
          </article>
          <article onClick={() => this.caseClose()}>
            {this.props.router.t("close-case")}
          </article>
          <article
            style={{ width: "40%" }}
            onClick={() => {
              if (!functions.checkPerm("515")) {
                Swal.fire({
                  icon: "error",
                  title: this.props.router.t("auth-err"),
                });
                return;
              }
              this.props.openModal(MODAL.GET_CREDIT_CUSTOMER);
            }}
          >
            {this.props.router.t("ticks")}
          </article>
          <article
            style={{ width: "40%" }}
            onClick={() => {
              if (!functions.checkPerm("509")) {
                Swal.fire({
                  icon: "error",
                  title: this.props.router.t("auth-err"),
                });
                return;
              }
              this.props.openModal(MODAL.COST);
            }}
          >
            {this.props.router.t("cost")}
          </article>
          <article
            style={{ width: "40%" }}
            onClick={() => this.handleNewTab("517")}
          >
            {this.props.router.t("manage")}
          </article>
          <article
            style={{ width: "40%" }}
            onClick={() => this.handleNewTab("516")}
          >
            {this.props.router.t("analysis")}
          </article>
          <article
            style={{
              width: "40%",
            }}
            onClick={() => {
              if (!Functions.checkPerm("521")) {
                Swal.fire({
                  title: this.props.router.t("auth-err"),
                  icon: "error",
                });
                return;
              }
              if (!this.props.getGlobalData.CurrencySettings) {
                Swal.fire({
                  icon: "warning",
                  title: this.props.router.t(
                    "currency-settings-button-warning"
                  ),
                });
                return;
              }
              this.setState({
                currencySettingsOpen: !this.state.currencySettingsOpen,
              });
            }}
          >
            {this.props.router.t("currency-settings")}
          </article>
          <article
            style={{ width: "40%" }}
            onClick={() => {
              if (!Functions.checkPerm("522")) {
                Swal.fire({
                  icon: "error",
                  title: this.props.router.t("auth-err"),
                });
                return;
              }
              this.props.router.navigate("/scales", { replace: true });
            }}
          >
            {this.props.router.t("scales")}
          </article>
          <article
            style={{
              width: "80%",
              background: this.state.stocksOpen ? "green" : "slategrey",
            }}
            onClick={() => {
              this.setState({
                stocksOpen: !this.state.stocksOpen,
              });
            }}
          >
            {this.props.router.t("stock-info")}
          </article>
          <article
            style={{
              width: "80%",
              background: this.state.settingsOpen ? "green" : "slategrey",
            }}
            onClick={() => {
              if (!Functions.checkPerm("525")) {
                Swal.fire({
                  icon: "error",
                  title: this.props.router.t("auth-err"),
                });
                return;
              }
              this.setState({
                settingsOpen: !this.state.settingsOpen,
              });
            }}
          >
            {this.props.router.t("settings")}
          </article>
        </div>
        <div className="bButtons">
          <article onClick={() => this.signOut()}>
            {this.props.router.t("sign-out")}
          </article>
        </div>
        <this.SettingsDialog />
        <this.StocksDialog />
        <this.ButtonSettingsDialog />
        <this.InterfaceSettingsDialog />
        <this.CurrencySettingsDialog />
        {/* <this.IntegrationDialog />
        <this.GetirDialog /> */}
      </section>
    );
  }
}

export default Operations;
